import CompLayout from "@/pcomponents/comp/layout/layout";
import DataFileListArea from "@/pages/comp/data_file/common/list";

function DataFileListPage(){
  
  return (
  <CompLayout>
    <DataFileListArea></DataFileListArea>
  </CompLayout>
  );
};
export default DataFileListPage;