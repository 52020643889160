import strFunc from "@/lib/lyg/string";

function ShowTreeBtnArea(props: any) {
  let myProps = {
    info: {},
    child_arr: [],
    show_tree_seq_arr: [],
    set_show_tree_seq_arr: (inData: any) => { },
    ...props
  };

  let info = myProps.info;
  let child_arr = myProps.child_arr;
  let is_show_tree = false;

  if (strFunc.str_in_array(info["a_seq"], myProps.show_tree_seq_arr) != -1) {
    is_show_tree = true;
  }
  let btn_style: any = {fontWeight:"bold"};
  if (is_show_tree) {
    btn_style["background"] = "#31a374";
  }

  const show_tree_seq_toggle = () => {
    if (is_show_tree) {
      let new_arr: any = [];
      for (let i = 0; i < myProps.show_tree_seq_arr.length; i++) {
        let tree_seq = myProps.show_tree_seq_arr[i];
        if (info["a_seq"] != tree_seq) {
          new_arr.push(tree_seq);
        }
      }
      myProps.set_show_tree_seq_arr(new_arr);
    } else {
      let new_arr = [...myProps.show_tree_seq_arr];
      new_arr.push(info["a_seq"]);

      myProps.set_show_tree_seq_arr(new_arr);
    }
  };

  return (
    <span className="ml-1">
      {child_arr.length > 0 &&
        <button className="btn-m btn-gray" style={btn_style}
          onClick={() => {
            show_tree_seq_toggle();
          }}>
          {is_show_tree ? "-" : "+"}
        </button>
      }
    </span>
  );
};
export default ShowTreeBtnArea;