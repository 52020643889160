import DataGroupFileUploadBtn from "@/pages/comp/basic/file/common/upload/data_upload_btn";

function ManageButtonArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    get_folder_list_by_ajax: (inData: any) => { },
    select_par_folder_arr: [],
    open_folder_manage_popup: () => { },
    go_delete_file_and_folder_by_ajax: (inData: any) => { },
    go_download_file: (inData: any) => { },
    ...props
  };

  let par_folder_info = null;
  if (myProps.select_par_folder_arr.length > 0) {
    par_folder_info = myProps.select_par_folder_arr[myProps.select_par_folder_arr.length - 1];
  }

  let file_init_data={
    a_public_sort:myProps.listOpt.s_public_sort,
  };

  const go_refresh_data = () => {
    myProps.list({ now_page: 1 });
    myProps.get_folder_list_by_ajax({});
  };

  return (
    <div>
      <div className="text-right">
        <div>
          <button className="btn btn-dark" onClick={() => { myProps.open_folder_manage_popup(); }}>폴더관리</button>
          <span className="ml-1">
            <DataGroupFileUploadBtn
              select_file_text={"파일업로드"}
              is_folder_upload={false}
              par_folder_info={par_folder_info}
              init_data={file_init_data}
              callback={go_refresh_data}
            ></DataGroupFileUploadBtn>
          </span>
          <span className="ml-1">
            <DataGroupFileUploadBtn
              select_file_text={"폴더업로드"}
              is_folder_upload={true}
              par_folder_info={par_folder_info}
              init_data={file_init_data}
              callback={go_refresh_data}
            ></DataGroupFileUploadBtn>
          </span>
        </div>
        <div className="mt-1">
          <button className="btn btn-dark" onClick={() => { myProps.go_download_file(); }}>다운로드</button>
          <button className="btn btn-red ml-1" onClick={() => { myProps.go_delete_file_and_folder_by_ajax({}); }}>삭제</button>
        </div>
      </div>
    </div>
  );
};
export default ManageButtonArea;