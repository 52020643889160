import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useDispatch, useSelector } from 'react-redux';
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import WriteInputFrame from '@/pages/comp/basic/file/frame/write_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function DataWriteArea(props: any) {
  let myProps = {
    is_update: false,
    row_data: {},
    callback: () => { },
    ...props
  };
  const user = useSelector((state: any) => state.user);
  let is_update = myProps.is_update;
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
    table_id: "group_board_data",
  };
  if (is_update) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }
  const fileWriteOneRef = useRef<any>(null);
  const [row_data, set_row_data] = useState({
    a_ymd:DateFunc.get_date_format(new Date(),"Ymd"),
    a_seq: "",
    a_group_seq: user.comp_group_info["a_seq"],
    a_mcomp_seq:user.comp_seq,
    a_mcomp_name: user.corp_name,
    a_public_sort:"comp",
    a_folder_seq:"",
    a_subject_name:"",
    a_is_top:"",
    a_title: "",
    a_content:"",
    a_writer: user.user_name,
    a_writer_mcomp_seq: user.comp_seq,
    a_writer_mcomp_user_seq:user.user_seq,
    a_start_date:DateFunc.get_date_format(new Date(),"Y-m-d"),
    a_color:"#000",
    ...myProps.row_data
  });
  let subject_select_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  useEffect(() => {
    if(!strFunc.is_empty(user.comp_group_info["a_seq"])){
      set_row_data({
        ...row_data,
        a_group_seq:user.comp_group_info["a_seq"]
      });
    }
  }, [user]);


  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name=="a_order_num"){
      value=strFunc.uncomma(value);
    }
    let change_row_data={
      ...row_data,
      ...{ [name]: value }
    };

    set_row_data(change_row_data);
  };

  const goWrite = () => {
    if(strFunc.is_empty(row_data.a_group_seq)){
      alert("그룹키가 없습니다.");
      return false;
    }
    if (row_data.a_title == "") {
      alert("이름 입력이 필요합니다.");
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let formJsonData = {
      "data_arr": [row_data],
      "is_default_val": "1",
      "is_update": "",
    };
    if (is_update) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data/write', formJsonData,
      get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let file_name_value = "";
          if (strFunc.is_empty(row_data.a_seq)) {
            let childFileInput = fileWriteOneRef.current.getFileInput();
            file_name_value = childFileInput.current.value;
          }
          if (file_name_value == "") {
            //alert("저장 되었습니다.");
            myProps.callback();
          } else {
            let last_info = response.data["data"][0];
            let priVal = last_info.a_ymd+","+last_info.a_seq;
            fileWriteOneRef.current.goWrite({
              "a_par_seq": priVal
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>과목</th>
              <td>
                <select className="row-input" name="a_subject_name" value={row_data.a_subject_name} onChange={handleInputChange}>
                  <option value="">선택없음</option>
                  {subject_select_option_arr.map((item: any, index: number) => {
                    return (<option key={index} value={item.value}>{item.text}</option>);
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td>
                <input type="text" className="row-input" name="a_title" value={row_data.a_title} onChange={handleInputChange} placeholder="제목" />
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <TextAreaComponent
                  name="a_content"
                  value={row_data.a_content}
                  onChange={handleInputChange}
                  placeholder="내용"
                ></TextAreaComponent>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        row_data.a_seq == "" &&
        <WriteInputFrame
          ref={fileWriteOneRef}
          init_data={{
            a_par_id: pageData.table_id,
            a_par_seq: row_data.a_ymd + "," + row_data.a_seq,
          }}
          callback={() => { myProps.callback(); }}
        ></WriteInputFrame>
      }
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.callback(); }}>닫기</button>
      </div>
      {
        row_data.a_seq != "" &&
        <FileListWriteFrame
          listOpt={
            {
              s_par_id: pageData.table_id,
              s_par_seq: row_data.a_ymd + "," + row_data.a_seq,
              s_start_date: row_data.a_start_date
            }
          }
        ></FileListWriteFrame>
      }
    </div>
  );
};
export default DataWriteArea;