import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    comp_seq:"",
    comp_sort:"",
    user_id:"",
    user_seq:"",
    user_name:"",
    corp_name:"",
    corp_seq:"",
    user_grade:"",
    main_user_seq:"",
    is_other_comp_login:"",
    stu_num_ciphers:"4",
    hmenuArr:[],
    smenuArr:[],
    tableKeyArr:{
      //"corp":["read","write","delete","update","list","display"],
    },
    sort_data:{},
    profile_url:"",
    profile_thum_url:"",
    logo_url:"",
    comp_group_info:{},
  },
  reducers: {
    removeUser: (state:any) => {
      state.comp_seq ="";
      state.comp_sort ="";
      state.user_id ="";
      state.user_seq ="";
      state.user_name ="";
      state.user_grade ="";
      state.main_user_seq ="";
    },
    setUser: (state:any, action:any) => {
      for(let key in state){
        if(action.payload[key]!=undefined){
          state[key] =action.payload[key];
        }
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { removeUser,setUser } = userSlice.actions

export default userSlice.reducer