function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    goDelete: () => { },
    openWritePopup: (sort: string, is_update: boolean) => { },
    ...props
  };

  return (
    <div>
      <div className="text-right">
        <button className="btn btn-dark"
          onClick={() => { myProps.openWritePopup("write", false); }}
        >등록</button>
        <button className="btn btn-dark ml-1"
          onClick={() => { myProps.openWritePopup("write", true); }}
        >수정</button>
        <button className="btn btn-red ml-1"
          onClick={myProps.goDelete}
        >삭제</button>
      </div>
    </div>
  );
};
export default SearchArea;