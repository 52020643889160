import folder_ico from "@/img/ico/folder_ico.png";
import strFunc from "@/lib/lyg/string";

function FolderListArea(props:any){
   let myProps={
    Style:{},
    folder_list:[],
    select_folder_seq:[],
    set_select_folder_seq: (inData: any) => { },
    select_par_folder_arr: [],
    set_select_par_folder_arr: (inData: any) => { },
    check_folder_seq_arr:[],
    set_check_folder_seq_arr:(inData:any)=>{},
    go_in_folder:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;

  const on_check_folder=(inData:any)=>{
    let opt_obj={
      folder_seq:"",
      ...inData
    };
    let folder_seq=opt_obj.folder_seq;
    if(strFunc.is_empty(folder_seq)){
      return false;
    }
    
    if(strFunc.str_in_array(folder_seq, myProps.check_folder_seq_arr)==-1){
      let new_arr=[...myProps.check_folder_seq_arr];
      new_arr.push(folder_seq);
      myProps.set_check_folder_seq_arr(new_arr);
    }else{
      let new_arr:any=[];
      for(let i=0;i<myProps.check_folder_seq_arr.length;i++){
        if(myProps.check_folder_seq_arr[i]!==folder_seq){
          new_arr.push(myProps.check_folder_seq_arr[i]);
        }
      }
      myProps.set_check_folder_seq_arr(new_arr);
    }
  };

  let get_folder_box_tags=()=>{
    let folder_box_tags=myProps.folder_list.map((item:any, index:number)=>{
      let is_checked=false;
      if(strFunc.str_in_array(item["a_seq"], myProps.check_folder_seq_arr)!==-1){
        is_checked=true;
      }
      return (
        <div key={index} className={Style.box} 
          onClick={(e:any)=>{
            let is_able_click=true;
            if(e.target.tagName=="INPUT"){
              is_able_click=false;
            }
            if(is_able_click){
              myProps.go_in_folder({
                folder_seq:item["a_seq"]
              });
            }
          }}>
          <input type="checkbox" className={Style.box_check_input} 
            checked={is_checked}
            onChange={()=>{
              on_check_folder({
                folder_seq:item["a_seq"]
              });
            }}/>
          <div className={Style.box_con}>
            <img src={folder_ico} className={Style.box_con_img} alt="folder_ico" />
          </div>
          <div className={Style.box_title_wrap}>
            {item["a_name"]}
          </div>
        </div>
      );
    });
    return folder_box_tags;
  };
  
  return (
  <div className={Style.box_wrap}>
    {get_folder_box_tags()}
  </div>
  );
};
export default FolderListArea;