import { useState, useRef, useEffect, useCallback, forwardRef } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';

function WritePopupContent(props: any) {
  let myProps = {
    "isUpdate": false,
    "init_data": {
      "a_par_id": "",
      "a_par_seq": "",
      "a_sort1": "",
      "a_sort2": "",
      "a_sort3": "",
    },
    "rowData": {},
    ...props,
  };
  const user = useSelector((state: any) => state.user);
  const fileInput = useRef(null);
  const writeForm = useRef(null);
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (myProps.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }

  const [rowData, setRowData] = useState({
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_comp_seq": user.comp_seq,
    "a_par_id": myProps.init_data.a_par_id,
    "a_par_seq": myProps.init_data.a_par_seq,
    "a_sort1": myProps.init_data.a_sort1,
    "a_sort2": myProps.init_data.a_sort2,
    "a_sort3": myProps.init_data.a_sort3,
    "a_writer": user.user_name,
    "a_create_seq": user.user_seq,
  });

  useEffect(() => {
    if (myProps.isUpdate) {
      let changeRowData: any = {};
      for (let key in rowData) {
        if (myProps.rowData[key] != undefined) {
          changeRowData[key] = myProps.rowData[key];
        }
      }
      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    setRowData({
      ...rowData,
      ...{ [key]: date_str }
    });
  };

  const goWrite = useCallback((e: any) => {
    if (fileInput.current && (fileInput.current as HTMLInputElement).value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }
    let file_len: any = 0;
    let files:any=[];
    if (fileInput.current) {
      files=(fileInput.current as HTMLInputElement).files;
      file_len = files.length;
      if (file_len == undefined) { file_len = 0; }
    }

    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }
    if (writeForm.current == null) {
      return false;
    }
    var form = writeForm.current as HTMLFormElement;
    // var formData = new FormData(form);
    var formData = new FormData();
    for (let i = 0; i < file_len; i++) {
      formData.append("input_file[]", files[i]);
    }
    let file_row_data_arr = [];
    for (let i = 0; i < file_len; i++) {
      file_row_data_arr.push({
        ...rowData
      });
    }
    formData.append("data_arr", JSON.stringify(file_row_data_arr));
    formData.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/group/file/file/receive', formData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.callback(response.data["data"]);
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });

  }, [rowData]);

  return (
    <div>
      <form id="write_form" method="post" ref={writeForm} >
        <div className="write-table-div mt-1">
          <table>
            <colgroup>
              <col width="110px"></col>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <th>구분</th>
                <td>
                  <input type="text" className="row-input" name="a_sort3" value={rowData.a_sort3} onChange={handleInputChange} placeholder="구분" />
                </td>
              </tr>
              <tr>
                <th>파일</th>
                <td>
                  <input type="file" id="files" className="row-input" name="input_file[]" ref={fileInput} multiple />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
}

export default WritePopupContent;
