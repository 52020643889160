function SearchArea(props: any) {
  let myProps = {
    infoArr: [],
    open_write_popup: (inData: any) => { },
    is_show_manage_btn: false,
    set_is_show_manage_btn: (inData: any) => { },
    is_show_all_tree: false,
    set_is_show_all_tree: (inData: any) => { },
    ...props
  };

  return (
    <div>
      <div>
        <button className="btn-s btn-dark" onClick={() => {
          myProps.open_write_popup({
            rowData: { a_order_num: myProps.infoArr.length },
          });
        }}>폴더추가</button>
        <span className="ml-1">
          <label>
            <input type="checkbox" checked={myProps.is_show_manage_btn} onChange={(e) => {
              myProps.set_is_show_manage_btn(e.target.checked);
            }} />
            <span className="ml-1">관리버튼보기</span>
          </label>
        </span>
        <span className="ml-1">
          <label>
            <input type="checkbox" checked={myProps.is_show_all_tree} onChange={(e) => {
              myProps.set_is_show_all_tree(e.target.checked);
            }} />
            <span className="ml-1">모든폴더보기</span>
          </label>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;