import strFunc from "@/lib/lyg/string";
import ManageBtnBox from "./area/manage_btn_box";
import ShowTreeBtnArea from "./area/show_tree_btn";

function TreeRowBox(props: any) {
  let myProps = {
    info: null,
    par_info: null,
    ListTreeFunc: null,
    change_order_num_by_ajax: (inData: any) => { },
    delete_by_ajax: (inData: any) => { },
    open_write_popup: (inData: any) => { },
    is_show_manage_btn: false,
    is_show_all_tree: false,
    show_tree_seq_arr: [],
    set_show_tree_seq_arr: (inData:any)=>{},
    select_folder_seq: "",
    set_select_folder_seq: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let depth = parseInt(strFunc.uncomma(info["a_depth"]));
  let child_depth = depth + 1;
  let child_html_str: any = "";
  let child_arr = [];
  let row_child_div_style: any = {};
  let padding_left = 0;
  if(child_depth){
    padding_left = 20
  }
  row_child_div_style["paddingLeft"] = padding_left + "px";

  let folder_name = "";
  if (info) {
    folder_name = info["a_name"];
    child_arr = info["child_arr"];
    if (child_arr && child_arr.length > 0) {
      child_html_str = child_arr.map((item: any, idx: number) => {
        return (
          <div key={idx}>
            <TreeRowBox info={item}
              par_info={info}
              ListTreeFunc={myProps.ListTreeFunc}
              change_order_num_by_ajax={myProps.change_order_num_by_ajax}
              delete_by_ajax={myProps.delete_by_ajax}
              open_write_popup={myProps.open_write_popup}
              is_show_manage_btn={myProps.is_show_manage_btn}
              is_show_all_tree={myProps.is_show_all_tree} 
              show_tree_seq_arr={myProps.show_tree_seq_arr}
              set_show_tree_seq_arr={myProps.set_show_tree_seq_arr}
              select_folder_seq={myProps.select_folder_seq}
              set_select_folder_seq={myProps.set_select_folder_seq}/>
          </div>
        );
      });
    }
  }

  let folrder_name_style:any={
    display:"inline-block",
    minWidth:"100px",
    lineHeight:"24px",
    cursor:"pointer",
    padding:"0px 5px",
    border:"1px solid #999",
    borderRadius:"5px",
    marginTop:"4px",
  };
  if(myProps.select_folder_seq==info["a_seq"]){
    folrder_name_style["color"]="#fff";
    folrder_name_style["background"]="#379d89";
    folrder_name_style["fontWeight"]="bold";
  }

  let is_show_tree=false;
  if(strFunc.str_in_array(info["a_seq"],myProps.show_tree_seq_arr)!=-1){
    is_show_tree=true;
  }
  if(myProps.is_show_all_tree){
    is_show_tree=true;
  }
  if(child_arr.length==0){
    is_show_tree=false;
  }

  return (
    <div>
      <div>
        <span style={folrder_name_style} 
          onClick={()=>{
            if(myProps.select_folder_seq==info["a_seq"]){
              myProps.set_select_folder_seq("");
            }else{
              myProps.set_select_folder_seq(info["a_seq"]);
            }
          }}>
          {folder_name}
        </span>
        {myProps.is_show_manage_btn &&
          <ManageBtnBox
            info={info}
            par_info={myProps.par_info}
            ListTreeFunc={myProps.ListTreeFunc}
            open_write_popup={myProps.open_write_popup}
            delete_by_ajax={myProps.delete_by_ajax}
            change_order_num_by_ajax={myProps.change_order_num_by_ajax}
          ></ManageBtnBox>
        }
        <ShowTreeBtnArea
          info={info}
          child_arr={child_arr}
          show_tree_seq_arr={myProps.show_tree_seq_arr}
          set_show_tree_seq_arr={myProps.set_show_tree_seq_arr}
        ></ShowTreeBtnArea>
      </div>
      {is_show_tree &&
        <div style={row_child_div_style}>
          {child_html_str}
        </div>
      }
    </div>
  );
};
export default TreeRowBox;