import DataWriteArea from "@/pages/comp/board/data/common/write";

function NoticeBoardWritePopup(props:any){
   let myProps={
    closePopup:()=>{},
    is_update:false,
    row_data:{},
    ...props
  };
  
  return (
  <div>
    <DataWriteArea
      is_update={myProps.is_update}
      row_data={myProps.row_data}
      callback={()=>{myProps.callback();myProps.closePopup();}}
    ></DataWriteArea>
  </div>
  );
};
export default NoticeBoardWritePopup;