import { useState, useRef, useEffect } from 'react';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import { useDispatch, useSelector } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import DataWritePopup from "@/pages/comp/board/data/popup/write";
import DataBoardViewPopup from "@/pages/comp/board/data/popup/view";
import FolderManagePopup from "@/pages/comp/board/data_folder/popup/manage";
import getXcolumnJson from "./xcolumn/list";
import SearchTabArea from "./area/search_tab";
import SearchArea from "./area/search";
import FolderListArea from "./area/folder_list";

function DataListArea(props: any) {
  let myProps = {
    ...props
  };
  const user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    "s_public_sort": "comp",//공개구분(super,master,teacher,comp)
    "s_addon_file": "1",
    "s_addon_mcomp_name": "1",
    "s_mcomp_seq": "",
    "s_subject_name": "",
    "s_title_like": "",
    "s_writer_like": "",
    "s_folder_seq": "",
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [folder_list, set_folder_list] = useState<any>([]);
  const [select_folder_seq, set_select_folder_seq] = useState("");

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//write
    is_update: false,
    row_data: {},
    title: "수정",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    list({});
    get_folder_list_by_ajax({});
  }, [user]);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(form_json_data);
    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      setInfoArr([]);
      ListComponentRef.current.setInfoArr([]);
      return false;
    }
    if (strFunc.is_empty(form_json_data["s_folder_seq"])) {
      // setInfoArr([]);
      // ListComponentRef.current.setInfoArr([]);
      // return false;
    }

    form_json_data["s_group_seq"] = user.comp_group_info["a_seq"];
    
    if(form_json_data["s_public_sort"]=="comp"){
      form_json_data["s_mcomp_seq"] = user["comp_seq"];
      if(strFunc.is_empty(form_json_data["s_mcomp_seq"])){
        setInfoArr([]);
        ListComponentRef.current.setInfoArr([]);
        return false;
      }
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //idx_num
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_folder_list_by_ajax = (inData: any) => {
    let opt_obj = {
      s_public_sort: listOpt.s_public_sort,
      ...inData
    };
    let form_json_data: any = {
      "s_public_sort": opt_obj.s_public_sort,
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      set_folder_list([]);
      return false;
    }
    form_json_data["s_group_seq"] = user.comp_group_info["a_seq"];

    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data_folder/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_folder_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = (inData: any) => {
    let opt_obj = {

      ...inData
    };
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const openWritePopup = (sort: string, is_update: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {
      a_folder_seq: select_folder_seq,
      a_public_sort: listOpt.s_public_sort,
    };

    if (is_update) {
      pop_title = "수정";
      let selected_row = ListComponentRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    } else {
      if (strFunc.is_empty(select_folder_seq)) {
        alert("폴더를 선택해주세요.");
        return false;
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "is_update": is_update,
      "row_data": tmp_rowData,
      "title": pop_title,
      "sort": sort
    });
  };

  const open_folder_manage_popup = () => {
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "폴더관리",
      "sort": "folder_manage"
    });
  };

  //rowOnClick:(rowData:any,idx:number,e:any)=>{},
  const rowOnClick = (rowData: any, idx: number, e: any) => {
    // setTimeout(()=>{
    //   openWritePopup("view", true);
    // },200);
  }

  const customCellRenderer = (params: any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "origin_val":origin_val,
      }
      */
    let render_str = params.value;
    if (params.key == "a_title") {
      render_str = (
        <div title={params.rowData.a_title}
          onClick={() => {
            setTimeout(() => {
              openWritePopup("view", true);
            }, 200);
          }}
          style={{ cursor: "pointer" }}>
          {strFunc.cut_str(params.rowData.a_title, 20, "..")}
        </div>
      );
    } else if (params.key === "row_view_file_cnt") {
      render_str = "";
      if (params.rowData.file_arr && params.rowData.file_arr.length > 0) {
        render_str = params.rowData.file_arr.length;
      }
    } else if (params.key === "row_view_mcomp_name") {
      render_str = "";
      if (params.rowData.mcomp_name) {
        render_str = (
          <span title={params.rowData.mcomp_name}>
            {strFunc.cut_str(params.rowData.mcomp_name, 6, "..")}
          </span>
        );
      }
    }
    return render_str;
  };

  return (
    <div>
      <SearchTabArea
        listOpt={listOpt}
        list={list}
        set_select_folder_seq={set_select_folder_seq}
        get_folder_list_by_ajax={get_folder_list_by_ajax}
      ></SearchTabArea>
      <div className="mt-1">
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          list={list}
        ></SearchArea>
      </div>
      <div className="mt-2 text-right">
        <button onClick={() => { openWritePopup("write", false); }} className="btn btn-dark">등록</button>
        <button onClick={() => { openWritePopup("write", true); }} className="btn btn-dark ml-1">수정</button>
        <button onClick={() => { goDelete({}) }} className="btn btn-red ml-1">삭제</button>
      </div>
      <div className="mt-2" style={{ display: "flex", gap: "10px" }}>
        <div style={{ minWidth: 300, flexGrow: 1 }}>
          <FolderListArea
            folder_list={folder_list}
            select_folder_seq={select_folder_seq}
            set_select_folder_seq={set_select_folder_seq}
            list={list}
            open_folder_manage_popup={open_folder_manage_popup}
          ></FolderListArea>
        </div>
        <div style={{ minWidth: 400, flexGrow: 1 }}>
          <ListComponent
            ref={ListComponentRef}
            infoArr={infoArr}
            xColumnArr={xColumnArr}
            gridOpt={{
              is_add_idx_num: true,
              is_add_checkbox: true,
              td_align: "center",
              customCellRenderer: customCellRenderer,
              rowOnClick: rowOnClick,
            }}
          ></ListComponent>
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
            onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <DataWritePopup
              is_update={popupData.is_update}
              row_data={popupData.row_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></DataWritePopup>
          }
          {popupData.sort === "view" &&
            <DataBoardViewPopup
              is_update={popupData.is_update}
              row_data={popupData.row_data}
              go_write_page={(sort: string, is_update: boolean) => { openWritePopup(sort, is_update); }}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></DataBoardViewPopup>
          }
          {popupData.sort === "folder_manage" &&
            <FolderManagePopup
              listOpt={{
                s_public_sort: listOpt.s_public_sort
              }}
              refresh_data={(inData: any) => {
                set_select_folder_seq("");
                get_folder_list_by_ajax({});
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></FolderManagePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default DataListArea;