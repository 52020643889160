import DataFolderManageArea from "@/pages/comp/board/data_folder/common/manage";

function FolderManagePopup(props: any) {
  let myProps = {
    listOpt: {},
    refresh_data: (inData: any) => { },
    closePopup: () => { },
    ...props
  };

  return (
    <div>
      <DataFolderManageArea 
        listOpt={myProps.listOpt}
        refresh_data={myProps.refresh_data}></DataFolderManageArea>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default FolderManagePopup;