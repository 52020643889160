import CompLayout from "@/pcomponents/comp/layout/layout";
import NoticeListArea from "@/pages/comp/board/board/common/notice_list";

function NoticeListPage(){
  
  return (
  <CompLayout>
    <NoticeListArea></NoticeListArea>
  </CompLayout>
  );
};
export default NoticeListPage;