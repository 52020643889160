import { useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import LoadingComponent from "@/pcomponents/common/content/loading";
import FolderFunc from "./func/folder_func";

function DataGroupFileUploadBtn(props: any) {
  let myProps = {
    "select_file_text": "파일업로드",
    "is_folder_upload": false,
    "par_folder_info": null,
    "init_data": {},
    "callback": () => { },
    ...props
  };
  let init_data={
    "a_public_sort": "comp",
    "a_par_id": "group_board_data_folder",
    "a_par_seq": "",
    "a_sort1": "",
    "a_sort2": "",
    "a_sort3": "",
    ...myProps.init_data
  };
  const user = useSelector((state: any) => state.user);
  const fileInput = useRef<any>(null);
  const writeForm = useRef<HTMLFormElement>(null);
  const rowData = {
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_group_seq": user.comp_group_info["a_seq"],
    "a_comp_seq": user.comp_seq,
    "a_public_sort": init_data.a_public_sort,
    "a_par_id": init_data.a_par_id,
    "a_par_seq": init_data.a_par_seq,
    "a_sort1": init_data.a_sort1,
    "a_sort2": init_data.a_sort2,
    "a_sort3": init_data.a_sort3,
    "a_writer": user.user_name,
    "a_create_seq": user.user_seq,
  };
  const upload_file_arr_ref = useRef<any>([]);
  const upload_file_idx_ref = useRef(0);
  const [refresh, set_refresh] = useState(false);
  const [is_view_btn, set_is_view_btn] = useState(true);
  const [is_load_display,set_is_load_display]=useState(false);
  const [load_cnt,set_load_cnt]=useState(0);

  useEffect(() => {
    if (myProps.is_folder_upload&&fileInput.current) {
      fileInput.current.webkitdirectory = "true";
    }
  }, [is_view_btn]);

  const goWriteFolder = () => {
    //폴더추출
    let upload_folder_arr: any = FolderFunc.get_folder_list_by_file_list({
      upload_file_arr: upload_file_arr_ref.current,
    });

    //첫부모세팅
    if (!strFunc.is_empty(myProps.par_folder_info)) {
      //첫번째 폴더는 부모폴더로 세팅
      for (let i = 0; i < upload_folder_arr.length; i++) {
        if (upload_folder_arr[i]["a_depth"] == "0") {
          upload_folder_arr[i]["a_par_folder_seq"] = myProps.par_folder_info["a_seq"];
        }
      }
      //부모폴더 있으면 있는만큼 뎁스 추가.
      let plus_depth = parseInt(myProps.par_folder_info["a_depth"] + "");
      plus_depth++;
      if (plus_depth > 0) {
        for (let i = 0; i < upload_folder_arr.length; i++) {
          upload_folder_arr[i]["a_depth"] = plus_depth + parseInt(upload_folder_arr[i]["a_depth"] + "");
        }
      }
    }
    //기본정보 세팅
    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      alert("그룹 정보가 없습니다.");
      go_cancel_file_select();
      return false;
    }
    let tmp_basic_folder_data = {
      a_seq: "",
      a_group_seq: user.comp_group_info["a_seq"],
      a_mcomp_seq: user.comp_seq,
      a_public_sort: rowData.a_public_sort,
      a_name: "",
      a_par_folder_seq: "",
      a_depth: "",
      a_order_num: "",
      a_create_mcomp_seq: user.comp_seq,
      a_create_seq: user.user_seq,
    };
    for (let i = 0; i < upload_folder_arr.length; i++) {
      upload_folder_arr[i] = {
        ...tmp_basic_folder_data,
        ...upload_folder_arr[i]
      };
    }

    //저장
    set_is_load_display(true);
    let form_json_data = {
      data_arr: upload_folder_arr,
      is_add_after_par_folder_seq: "1",
    };
    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/group/data/board_data_folder/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //키세팅
          let after_folder_arr = response.data["data"];
          for (let i = 0; i < upload_file_arr_ref.current.length; i++) {
            let upload_file = upload_file_arr_ref.current[i];
            for (let j = 0; j < after_folder_arr.length; j++) {
              let after_folder_info = after_folder_arr[j];
              let file_path = after_folder_info["relate_path"] + "/" + upload_file["name"];
              if (upload_file["webkitRelativePath"] == file_path) {
                upload_file_arr_ref.current[i]["a_par_folder_seq"] = after_folder_info["a_seq"];
              }
            }
          }
          upload_file_idx_ref.current = 0;
          go_write_file_loop();
        } else {
          alert(response.data["msg"]);
          set_is_load_display(false);
        }
      });
  };

  const go_write_file_loop = () => {
    let upload_file_idx = upload_file_idx_ref.current;
    if (upload_file_arr_ref.current && upload_file_arr_ref.current.length > 0
      && upload_file_idx < upload_file_arr_ref.current.length) {

      let file_info: any = upload_file_arr_ref.current[upload_file_idx];
      let tmpRowData:any = {
        ...rowData,
        ...init_data,
        a_par_seq: 0,
      };
      if(!strFunc.is_empty(file_info["a_par_folder_seq"])){
        tmpRowData["a_par_seq"]=file_info["a_par_folder_seq"];
      }
      const form_json_data = new FormData();
      form_json_data.append('file_arr[]', file_info);
      form_json_data.append("data_arr", JSON.stringify([tmpRowData]));
      form_json_data.append("file_server_url", process.env.REACT_APP_FILE_SERVER_URL+"");
      form_json_data.append("authorization", localStorage.intoyou_token);

      my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/group/file/file/receive', form_json_data, get_axios_data())
        .then((response) => {
          if (response.data["result"] === "true") {
            upload_file_idx_ref.current++;
            set_load_cnt(upload_file_idx_ref.current);
            set_refresh(!refresh);
            go_write_file_loop();
          } else {
            set_is_load_display(false);
            alert(response.data["msg"]);
          }
        });
    } else {
      go_cancel_file_select();
      set_is_load_display(false);
      alert("완료");
      myProps.callback({});
      return false;
    }
  };

  const goWrite = () => {
    let tmpRowData = {
      ...rowData,
      ...init_data,
    };
    if (tmpRowData.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      go_cancel_file_select();
      return false;
    }
    if (!strFunc.is_empty(myProps.par_folder_info)) {
      for (let i = 0; i < upload_file_arr_ref.current.length; i++) {
        upload_file_arr_ref.current[i]["a_par_folder_seq"]=myProps.par_folder_info["a_seq"];
      }
    }

    upload_file_idx_ref.current = 0;
    set_is_load_display(true);
    go_write_file_loop();
  };

  const go_cancel_file_select=()=>{
    fileInput.current.value = "";
    upload_file_idx_ref.current = 0;
    upload_file_arr_ref.current = [];
    set_refresh(!refresh);
    set_is_view_btn(false);
    setTimeout(() => {
      set_is_view_btn(true);
    }, 100);
  };

  const handleFileChange = (e: any) => {
    upload_file_arr_ref.current = e.target.files;
    if (upload_file_arr_ref.current && upload_file_arr_ref.current.length > 0) {
      if (confirm("파일을 업로드 하시겠습니까?") === false) {
        return false;
      }
      if (myProps.is_folder_upload) {
        goWriteFolder();
      } else {
        goWrite();
      }
    }
    set_refresh(!refresh);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <LoadingComponent is_display={is_load_display} 
        text={"업로드중.."+load_cnt+"/"+upload_file_arr_ref.current.length} ></LoadingComponent>
      {is_view_btn&&
      <form method="post" ref={writeForm} >
        <span className="btn btn-sky" onClick={(e: any) => {
          e.target.parentElement.querySelector(".input_file")?.click();
        }}  >
          {myProps.select_file_text}
          {(upload_file_arr_ref.current && upload_file_arr_ref.current.length > 0) &&
            "(" + (upload_file_idx_ref.current + 1) + "/" + upload_file_arr_ref.current.length + ")"
          }
        </span>
        <input type="file" className="input_file" name="input_file[]" ref={fileInput}
          multiple
          hidden
          onChange={handleFileChange}
          style={{ width: 100 }} />
      </form>
      }
    </div>
  );
};
export default DataGroupFileUploadBtn;