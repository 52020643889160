//import {default_folder_info} from "@/pcomponents/common/content/data/folder/data/default_data";
export const default_folder_info={
  a_seq:"",
  a_group_seq:"",
  a_public_sort:"",
  a_name:"",
  a_par_folder_seq:"",
  a_depth:"",
  a_par_depth_path_str:"",
  a_explan:"",
  a_order_num:"",
  a_create_mcomp_seq:"",
  a_create_user_seq:"",
  a_create_date:"",
  a_update_date:"",
};