import CompLayout from "@/pcomponents/comp/layout/layout";
import DataListArea from "@/pages/comp/board/data/common/list";

function DataListPage(){
  
  return (
  <CompLayout>
    <DataListArea></DataListArea>
  </CompLayout>
  );
};
export default DataListPage;