import HomeMainPage from "@/pages/home/main";
import CompMainPage from "@/pages/comp/main";
import LoginPage from "@/pages/comp/login";
import BoardManageListPage from "@/pages/comp/board/manage/list";
import NoticeListPage from "@/pages/comp/board/notice/list";
import DataFolderManagePage from "@/pages/comp/board/data_folder/manage";
import DataListPage from "@/pages/comp/board/data/list";
import DataFileListPage from "@/pages/comp/data_file/list";

const routerArr:object[] = [
  {
    path: "/",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/comp",
    element: <CompMainPage></CompMainPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/comp/board/manage/list",
    element: <BoardManageListPage></BoardManageListPage>,
  },
  {
    path: "/comp/board/data/folder/manage",
    element: <DataFolderManagePage></DataFolderManagePage>,
  },
  {
    path: "/comp/board/data/list",
    element: <DataListPage></DataListPage>,
  },
  {
    path: "/comp/board/notice/list",
    element: <NoticeListPage></NoticeListPage>,
  },
  {
    path: "/comp/data_file/list",
    element: <DataFileListPage></DataFileListPage>,
  },
];

export default routerArr;