import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import {default_folder_info} from "@/pcomponents/common/content/data/folder/data/default_data";
import strFunc from '@/lib/lyg/string';
import WriteArea from "@/pages/comp/board/data_folder/common/write/area/write";

function WriteFolderPopup(props: any) {
  let myProps = {
    "isUpdate": false,
    "init_data": {},
    "rowData": {},
    par_folder_info: null,
    callback_data: () => { },
    closePopup: () => { },
    ...props
  };

  const user = useSelector((state: any) => state.user);
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (myProps.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }

  const [rowData, setRowData] = useState({
    ...default_folder_info,
    ...myProps.rowData,
  });

  useEffect(() => {
    if(myProps.isUpdate==false){
      let change_row_data:any={
        "a_seq": "",
        "a_mcomp_seq": user.comp_seq,
        "a_create_mcomp_seq": user.comp_seq,
        "a_create_seq": user.user_seq,
      };
      if(user.comp_group_info){
        change_row_data["a_group_seq"]=user.comp_group_info["a_seq"];
      }
      if(myProps.par_folder_info){
        change_row_data["a_par_folder_seq"]=myProps.par_folder_info["a_seq"];
        change_row_data["a_depth"]=parseInt(strFunc.uncomma(myProps.par_folder_info["a_depth"]))+1;
        if(myProps.par_folder_info.child_arr){
          change_row_data["a_order_num"]=myProps.par_folder_info.child_arr.length;
        }
      }
      setRowData({
        ...rowData,
        ...change_row_data
      });
    }
  }, []);

  const goWrite = (e: any) => {
    let add_row_data={...rowData};
    let is_update="";
    if(myProps.isUpdate){
      is_update="1";
    }

    if(strFunc.is_empty(add_row_data["a_name"])){
      alert("폴더명을 입력해 주세요.");
      return false;
    }

    if(confirm(pageData["confirm_msg"])==false){
      return false;
    }

    let form_json_data:any={
      data_arr:[add_row_data],
      is_update:is_update
    };

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/group/data/board_data_folder/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.callback_data(response.data["data"]);
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  return (
    <div>
      <div>
        <WriteArea
          rowData={rowData}
          setRowData={setRowData}
        ></WriteArea>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite} >{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default WriteFolderPopup;