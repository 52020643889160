function WriteArea(props:any){
   let myProps={
    rowData:{},
    setRowData:(inData:any)=>{},
    ...props
  };
  let rowData=myProps.rowData;

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    myProps.setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };
  
  return (
  <div className="write_table_small">
    <table>
      <colgroup>
        <col width={"20%"}></col>
        <col width={"30%"}></col>
        <col width={"20%"}></col>
        <col width={"30%"}></col>
      </colgroup>
      <tbody>
        <tr>
          <th>폴더명</th>
          <td colSpan={3}>
            <input type="text" className="row-input" name="a_name" value={rowData.a_name}
             onChange={handleInputChange} placeholder="폴더명" autoComplete="off" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  );
};
export default WriteArea;