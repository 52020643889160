function ManageBtnBox(props: any) {
  let myProps = {
    info: null,
    par_info:null,
    ListTreeFunc: null,
    open_write_popup: (inData: any) => { },
    delete_by_ajax: (inData: any) => { },
    change_order_num_by_ajax: (inData: any) => { },
    ...props
  };
  let info = myProps.info;

  return (
    <span className="ml-1">
      <button className="btn-m btn-dark ml-2" onClick={() => {
        myProps.open_write_popup({
          par_folder_info: info,
        });
      }}>추가</button>
      <button className="btn-m btn-sky ml-1" onClick={() => {
        myProps.open_write_popup({
          rowData: info,
          isUpdate: true,
        });
      }}>수정</button>
      <button className="btn-m btn-red ml-1" onClick={() => {
        let tmp_del_info_arr = [info];
        if (myProps.ListTreeFunc) {
          let tmp_child_del_info_arr = myProps.ListTreeFunc.get_child_from_info({
            info: info
          });
          tmp_del_info_arr = tmp_del_info_arr.concat(tmp_child_del_info_arr);
        }
        if (tmp_del_info_arr) {
          myProps.delete_by_ajax({
            data_arr: tmp_del_info_arr,
          });
        }
      }}>삭제</button>
      <button className="btn-m btn-gray ml-1" onClick={() => {
        myProps.change_order_num_by_ajax({
          info: info,
          plus_minus: "-",
          par_info: myProps.par_info,
        });
      }}>▲</button>
      <button className="btn-m btn-gray ml-1" onClick={() => {
        myProps.change_order_num_by_ajax({
          info: info,
          plus_minus: "+",
          par_info: myProps.par_info,
        });
      }}>▼</button>
    </span>
  );
};
export default ManageBtnBox;