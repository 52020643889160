class FolderFunc {
  static default_folder_data = {
    a_name: "",
    a_depth: 0,
    idx_num: 0,
    par_idx_num: 0,
    relate_path:"",
  };
  static folder_idx_num=0;
  static get_folder_list_by_file_list(inData: any) {
    let opt_obj = {
      upload_file_arr: [],
      ...inData
    };
    let this_obj = this;
    let folder_arr: any = [];
    this_obj.folder_idx_num=0;
    //폴더추출
    for (let i = 0; i < opt_obj.upload_file_arr.length; i++) {
      let tmp_folder = opt_obj.upload_file_arr[i];
      let folder_str = tmp_folder["webkitRelativePath"].replace("/" + tmp_folder["name"], "");
      let folder_str_arr = folder_str.split("/");
      
      for (let j = 0; j < folder_str_arr.length; j++) {
        let tmp_folder_name = folder_str_arr[j];
        let par_folder_name = "";
        if (j > 0) {
          par_folder_name = folder_str_arr[j - 1];
        }
        folder_arr = this_obj.add_folder_by_filter({
          add_data: {
            ...this_obj.default_folder_data,
            a_name: tmp_folder_name,
            a_depth: j,
            relate_path:folder_str,
          },
          par_folder_name: par_folder_name,
          upload_file_arr: folder_arr,
        });
      }
    }

    return folder_arr;
  };
  static add_folder_by_filter(inData: any) {
    let opt_obj = {
      add_data: {},
      par_folder_name: "",
      upload_file_arr: [],
      ...inData
    };
    let this_obj = this;
    let upload_file_arr = opt_obj.upload_file_arr;
    let add_data = opt_obj.add_data;
    let is_able_add = true;
    //이미 있는지 확인
    for (let i = 0; i < upload_file_arr.length; i++) {
      let tmp_file = upload_file_arr[i];
      if (tmp_file["a_depth"] == add_data["a_depth"]
        && tmp_file["a_name"] == add_data["a_name"]
      ) {
        is_able_add = false;
      }
    }

    if (is_able_add) {
      //부모폴더 idx 얻기
      let par_depth = parseInt(add_data["a_depth"] + "") - 1;
      for (let i = 0; i < upload_file_arr.length; i++) {
        let tmp_file = upload_file_arr[i];
        if (tmp_file["a_depth"] == par_depth
          && tmp_file["a_name"] == opt_obj.par_folder_name
        ) {
          add_data["par_idx_num"] = tmp_file["idx_num"];
        }
      }
      this_obj.folder_idx_num++;
      add_data["idx_num"] = this_obj.folder_idx_num;
      upload_file_arr.push(add_data);
    }
    return upload_file_arr;
  };
}
export default FolderFunc;