import {
  createBrowserRouter,
} from "react-router-dom";

import compRouterArr from "./comp/index";

const router = createBrowserRouter([
  ...compRouterArr,
  {
    path: "*",
    element: <div>404 Not Found!!</div>,
  },
]);

export default router;