import { Suspense, lazy } from 'react';
import { RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import router from "@/router/index";
import store from '@/store/store';

function InitApp() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </Provider>
  );
}

export default InitApp;