import GNBArea from "@/pcomponents/comp/layout/menu/gnb";
import prj_logo_img from "@/img/logo/sntb_logo.png";
import { Link, useNavigate } from 'react-router-dom';
import CheckLoginComponent from '@/pcomponents/comp/check/login_check';
import checkVersion from '@/pcomponents/comp/check/version/check_version';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { setUser, removeUser } from '@/store/user/user';

const Header = (props: any) => {
  const navigate = useNavigate();
  let user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    checkVersion();
  }, []);

  const goLogOut = () => {
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/login/logout', {
      token_id: localStorage.intoyou_token,
    }, get_axios_data()).then((response) => {
      dispatch(removeUser());
      localStorage.removeItem("intoyou_token");
      navigate("/login");
    });
  };

  let group_name_str="";
  if(user.comp_group_info&&user.comp_group_info["a_name"]){
    group_name_str=user.comp_group_info["a_name"];
  }

  return (
    <div id="header">
      <CheckLoginComponent></CheckLoginComponent>
      <div className="header_con">
        <div className="header_top_wrap">
          <div className="logo_area">
            <a onClick={() => { navigate("/comp"); }}>
              <img src={user.logo_url ? user.logo_url : prj_logo_img} alt="로고" />
            </a>
          </div>
          <div className="header_top_link_wrap">
            <a className="header_top_link_btn"
              onClick={() => {
                //navigate("/comp/mypage");
              }} >
                <span style={{color:"green"}}>{group_name_str+" "}</span>
                {user.user_name} 님</a>

            <button className="btn-s2 btn-line-gray ml-4" onClick={goLogOut} >로그아웃</button>
          </div>
        </div>
        {/* <div className="gnb_wrap">
          <GNBArea></GNBArea>
        </div> */}
      </div>
    </div>
  );
};

export default Header;