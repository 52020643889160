import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useDispatch, useSelector } from 'react-redux';
import { setUser,removeUser } from '@/store/user/user';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';

function CheckLoginComponent(props: any) {
  let myProps = {
    isGoToMainAtLogin: false,
    "onGoLoginPage": () => { },
    is_reset_login_token_create_date:false,
    is_refresh_token:false,
    ...props
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    if(myProps.is_reset_login_token_create_date){
      localStorage.login_token_create_date="";
    }
    checkLogin();
  }, []);

  const checkLogin = () => {
    //키가있으면 로그인체크
    if (localStorage.intoyou_token) {
      let check_form_json: any = {
        token_id: localStorage.intoyou_token,
      };
      if (localStorage.login_token_create_date == DateFunc.get_date_format(new Date(), "Y-m-d h")) {
        check_form_json["is_add_client_info"] = "";
      } else {
        check_form_json["is_add_client_info"] = "1";
      }
      if (strFunc.is_empty(user.user_seq)) {
        check_form_json["is_add_client_info"] = "1";
      }
      if(user.is_other_comp_login=="1"){
        check_form_json["is_login_log"] = "";
      }
      if(myProps.is_refresh_token){
        check_form_json["is_refresh"] = "1";
      }
      my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/login/check_login', check_form_json,get_axios_data())
        .then((response) => {
          if (response.data["result"] === "true") {
            localStorage.intoyou_token = response.data.data["token_id"];
            if (response.data.data["client_info"]) {
              localStorage.login_token_create_date = DateFunc.get_date_format(new Date(), "Y-m-d h");
              let pre_user_seq = user.user_seq;
              var client_info = response.data.data["client_info"];
              dispatch(setUser(client_info));
              localStorage.sort_data_arr = JSON.stringify(client_info.sort_data);
              if (strFunc.is_empty(pre_user_seq)) {
                goToMainPage(client_info.comp_sort, client_info);
              }
            }else{
              if (myProps.isGoToMainAtLogin) {
                goToMainPage(user.comp_sort, user);
              }
            }
          } else {
            goLoginPageByUrl();
          }
        })
        .catch(function (error) {
          if (error.code == "ERR_NETWORK") {
            alert("인터넷 연결 오류.");
            //goLoginPageByUrl();
            // if(confirm("인터넷 연결 오류. 다시 시도 하시겠습니까?")){
            //   checkLogin();
            // }
          }
        });
    } else {
      goLoginPageByUrl();
    }
  };
  const goToMainPage = (comp_sort: string, client_info: any) => {
    if (!myProps.isGoToMainAtLogin) {
      return false;
    }
    //학생,학부모는 자동로그인 안한다. 로그인페이지면 로그아웃시키기
    if (location.pathname == "/login") {
      if(client_info.user_grade=="student"||client_info.user_grade == "parent"){
        goLogOut();
        return false;
      }
    }
    let path_arr = location.pathname.split("/");
    if (path_arr.length > 1 && path_arr[1] == "test") {
      if (client_info.comp_seq != 1 || client_info.comp_sort != "main" || client_info.user_grade != "master") {
        navigate("/");
      }
    }
    if (myProps.isGoToMainAtLogin) {
      navigate("/comp");
    }
  };
  const goLoginPageByUrl = () => {
    myProps.onGoLoginPage();
    navigate("/login");
  };

  const goLogOut=()=>{
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/logout',{ 
      token_id: localStorage.intoyou_token,
    },get_axios_data()).then((response) => {
      dispatch(removeUser());
      localStorage.removeItem("intoyou_token");
      if (location.pathname != "/login") {
        navigate("/login");
      }
    });
  };

  return (
    <></>
  );
}

export default CheckLoginComponent;