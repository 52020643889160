import file_ico from "@/img/ico/file_ico.png";
import strFunc from "@/lib/lyg/string";

function FileListArea(props: any) {
  let myProps = {
    Style: {},
    infoArr: [],
    check_file_seq_arr: [],
    set_check_file_seq_arr: (inData: any) => { },
    open_view_file_slide_popup: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;

  const on_check_file=(inData:any)=>{
    let opt_obj={
      file_pri_val:"",
      ...inData
    };
    let file_pri_val=opt_obj.file_pri_val;
    if(strFunc.is_empty(file_pri_val)){
      return false;
    }
    
    if(strFunc.str_in_array(file_pri_val, myProps.check_file_seq_arr)==-1){
      let new_arr=[...myProps.check_file_seq_arr];
      new_arr.push(file_pri_val);
      myProps.set_check_file_seq_arr(new_arr);
    }else{
      let new_arr:any=[];
      for(let i=0;i<myProps.check_file_seq_arr.length;i++){
        if(myProps.check_file_seq_arr[i]!==file_pri_val){
          new_arr.push(myProps.check_file_seq_arr[i]);
        }
      }
      myProps.set_check_file_seq_arr(new_arr);
    }
  };

  const get_file_box_tags = () => {
    let file_box_tags = myProps.infoArr.map((item: any, index: number) => {
      let is_checked=false;
      let file_pri_val=item["a_ymd"]+","+item["a_seq"];
      if(strFunc.str_in_array(file_pri_val, myProps.check_file_seq_arr)!==-1){
        is_checked=true;
      }

      let is_img=false;
      let img_src=file_ico;
      let img_style:any={};
      if(item.is_image=="1"){
        is_img=true;
        img_style={width:"auto"};
        img_src=item["thum_img_url"];
        if(item["a_ext"]=="png"){
          img_src=item["img_url"];
        }
      }

      let file_size=parseInt(strFunc.uncomma(item["a_size"]));
      let file_size_unit="KB";
      if(file_size>0){
        file_size=Math.round(file_size/1024);
        if(file_size>1000){
          file_size=Math.round(file_size/1024);
          file_size_unit="MB";
        }
        if(file_size>1000){
          file_size=Math.round(file_size/1024);
          file_size_unit="GB";
        }
      }
      return (
        <div key={index} className={Style.box} onClick={(e:any)=>{
          let is_able_open=true;
          if(e.target.tagName=="INPUT"){
            is_able_open=false;
          }
          if(is_img!=true){
            is_able_open=false;
          }
          if(is_able_open){
            myProps.open_view_file_slide_popup({
              select_file_pri_val:file_pri_val
            });
          }
        }}>
          <input type="checkbox" className={Style.box_check_input} 
            checked={is_checked}
            onChange={()=>{
              on_check_file({
                file_pri_val:file_pri_val
              });
            }}/>
          <div className={Style.box_con}>
            <img src={img_src} className={Style.box_con_img} alt="file_ico"
              style={img_style} />
          </div>
          <div className={Style.box_title_wrap}>
            {item["a_oriname"]}
          </div>
          <div className={Style.box_file_size_wrap}>
            {file_size}{file_size_unit}
          </div>
        </div>
      );
    });
    return file_box_tags;
  };

  return (
    <div className={Style.box_wrap} >
      {get_file_box_tags()}
    </div>
  );
};
export default FileListArea;