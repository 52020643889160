import NoticeWriteArea from "@/pages/comp/board/board/common/notice_write";

function NoticeBoardWritePopup(props:any){
   let myProps={
    closePopup:()=>{},
    is_update:false,
    row_data:{},
    ...props
  };
  
  return (
  <div>
    <NoticeWriteArea
      is_update={myProps.is_update}
      row_data={myProps.row_data}
      callback={()=>{myProps.callback();myProps.closePopup();}}
    ></NoticeWriteArea>
  </div>
  );
};
export default NoticeBoardWritePopup;