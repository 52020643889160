import ListTreeArea from "@/pages/comp/board/data_folder/common/manage/area/list_tree";
import { useState, useRef, useEffect } from 'react';

function FolderListArea(props:any){
   let myProps={
    folder_list:[],
    select_folder_seq:"",
    set_select_folder_seq:(inData:any)=>{},
    list:(inData:any)=>{},
    open_folder_manage_popup:()=>{},
    ...props
  };
  
  const [show_tree_seq_arr,set_show_tree_seq_arr]=useState([]);

  return (
  <div>
    <div className="text-left" style={{lineHeight:"25px"}}>
      폴더목록
      <span className="btn btn-dark ml-1"
        onClick={()=>{
          myProps.open_folder_manage_popup();
        }}>관리</span>
    </div>
    <ListTreeArea
      infoArr={myProps.folder_list}
      is_show_manage_btn={false}
      is_show_all_tree={false}
      show_tree_seq_arr={show_tree_seq_arr}
      set_show_tree_seq_arr={set_show_tree_seq_arr}
      select_folder_seq={myProps.select_folder_seq}
      set_select_folder_seq={(inData:any)=>{
        myProps.set_select_folder_seq(inData);
        myProps.list({
          now_page:1,
          s_folder_seq:inData
        });
      }}
    ></ListTreeArea>
  </div>
  );
};
export default FolderListArea;