import ManageWritePage from "@/pages/comp/board/manage/common/write";

function ManageBoardWritePopup(props:any){
   let myProps={
    closePopup:()=>{},
    is_update:false,
    row_data:{},
    ...props
  };
  
  return (
  <div>
    <ManageWritePage
      is_update={myProps.is_update}
      row_data={myProps.row_data}
      callback={()=>{myProps.callback();myProps.closePopup();}}
    ></ManageWritePage>
  </div>
  );
};
export default ManageBoardWritePopup;