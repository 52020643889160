import strFunc from '@/lib/lyg/string';
import FileListWriteFrame from '@/pages/comp/basic/file/frame/write';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function DataViewArea(props: any) {
  let myProps = {
    is_update: false,
    row_data: {},
    callback: (inData: any) => { },
    go_write_page: (sort: string, is_update: boolean) => { },
    ...props
  };
  let row_data = myProps.row_data;
  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
    table_id: "group_board_data",
  };

  const go_write_page = () => {
    myProps.callback();
    myProps.go_write_page("write", true);
  };

  return (
    <div>
      <div className="write-table-div mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>제목</th>
              <td>
                {row_data.a_title}
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <MultyLineTextView
                  text={row_data.a_content}
                ></MultyLineTextView>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                과목: {row_data.a_subject_name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={() => { go_write_page(); }}>수정</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.callback(); }}>닫기</button>
      </div>
      {
        row_data.a_seq != "" &&
        <FileListWriteFrame
          isView={true}
          listOpt={
            {
              s_par_id: pageData.table_id,
              s_par_seq: row_data.a_ymd + "," + row_data.a_seq,
              s_start_date: row_data.a_start_date
            }
          }
        ></FileListWriteFrame>
      }
    </div>
  );
};
export default DataViewArea;