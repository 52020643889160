import { useRef, forwardRef, useImperativeHandle } from 'react';

const DownLoadFileOne = forwardRef((props: any, ref) => {
  let myProps = {
    ...props
  };

  const downloadFormRef = useRef<any>(null);

  const go_download = (inData: any) => {
    let opt_obj = {
      server_url:"",
      file_pri_val: "",
      ...inData
    };
    let server_url=opt_obj.server_url;
    let file_pri_val=opt_obj.file_pri_val;

    if (downloadFormRef.current == null) {
      return false;
    }

    downloadFormRef.current.action = server_url + "/api/group_file/download/" + file_pri_val;
    downloadFormRef.current.submit();
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    go_download
  }));

  return (
    <>
      <form ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
      </form>
    </>
  );
});
export default DownLoadFileOne;