import DataViewArea from "@/pages/comp/board/data/common/view";

function DataBoardViewPopup(props:any){
   let myProps={
    closePopup:()=>{},
    is_update:false,
    row_data:{},
    go_write_page: (sort: string, is_update: boolean) => { },
    ...props
  };
  
  return (
  <div>
    <DataViewArea
      is_update={myProps.is_update}
      row_data={myProps.row_data}
      callback={()=>{myProps.callback();myProps.closePopup();}}
      go_write_page={myProps.go_write_page}
    ></DataViewArea>
  </div>
  );
};
export default DataBoardViewPopup;