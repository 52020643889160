import { useState, useRef, useEffect } from 'react';
import getXcolumnJson from "./xcolumn/list";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import { useDispatch, useSelector } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import NoticeBoardWritePopup from "@/pages/comp/board/notice/popup/write";
import SearchArea from "./area/search";

function NoticeListArea(props: any) {
  let myProps = {
    ...props
  };
  const user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//write
    is_update: false,
    row_data: {},
    title: "수정",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(form_json_data);
    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      setInfoArr([]);
      ListComponentRef.current.setInfoArr([]);
      return false;
    }
    form_json_data["s_group_seq"] = user.comp_group_info["a_seq"];

    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_notice/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //idx_num
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_notice/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const openWritePopup = (sort: string, is_update: boolean) => {
    let pop_title = "등록";
    let tmp_rowData = {
      a_stu_grade: listOpt.s_stu_grade,
      a_stu_season: listOpt.s_stu_season
    };
    if (is_update) {
      pop_title = "수정";
      let selected_row = ListComponentRef.current.getSelectedRows();
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "is_update": is_update,
      "row_data": tmp_rowData,
      "title": pop_title,
      "sort": sort
    });
  };

  const customCellRenderer = (params: any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "origin_val":origin_val,
      }
      */
    return params.value;
  };

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          list={list}
          goDelete={goDelete}
          openWritePopup={openWritePopup}
        ></SearchArea>
      </div>
      <div className="mt-1">
        <ListComponent
          ref={ListComponentRef}
          infoArr={infoArr}
          xColumnArr={xColumnArr}
          gridOpt={{
            is_add_idx_num: true,
            is_add_checkbox: true,
            td_align: "center",
            customCellRenderer: customCellRenderer,
          }}
        ></ListComponent>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <NoticeBoardWritePopup
              is_update={popupData.is_update}
              row_data={popupData.row_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></NoticeBoardWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default NoticeListArea;