import CompLayout from "@/pcomponents/comp/layout/layout";
import BoardManageListArea from "@/pages/comp/board/manage/common/list";
function BoardManageListPage(){
  
  return (
  <CompLayout>
    <BoardManageListArea></BoardManageListArea>
  </CompLayout>
  );
};
export default BoardManageListPage;