import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import ListTreeFunc from "./func/list_func";
import TreeRowBox from "./row_box";

function ListTreeArea(props:any){
   let myProps={
    infoArr:[],
    list:(inData:any)=>{},
    delete_by_ajax:(inData:any)=>{},
    open_write_popup:(inData:any)=>{},
    is_show_manage_btn: false,
    is_show_all_tree: false,
    show_tree_seq_arr: [],
    set_show_tree_seq_arr: (inData:any)=>{},
    select_folder_seq:"",
    set_select_folder_seq:(inData:any)=>{},
    ...props
  };
  let infoArr=myProps.infoArr;

  let tree_data=ListTreeFunc.get_tree_data({
    infoArr:infoArr
  });

  const change_order_num_by_ajax=(inData:any)=>{
    let opt_obj={
      info:null,
      par_info:null,
      plus_minus:"+",//+,-
      ...inData
    };

    let info=opt_obj.info;
    let plus_minus=opt_obj.plus_minus;
    let child_info_arr=[];
    if(opt_obj.par_info&&opt_obj.par_info["child_arr"]){
      child_info_arr=opt_obj.par_info["child_arr"];
    }
    if(strFunc.is_empty(info["a_par_folder_seq"])&&info["a_depth"]=="0"){
      for(let i=0;i<infoArr.length;i++){
        if(infoArr[i]["a_depth"]==info["a_depth"]){
          child_info_arr.push(infoArr[i]);
        }
      }
    }
    
    let now_idx=-1;
    let next_idx=-1;
    for(let i=0;i<child_info_arr.length;i++){
      let child_info=child_info_arr[i];
      child_info_arr[i]["a_order_num"]=i;
      if(child_info["a_seq"]==info["a_seq"]){
        now_idx=i;
      }
    }
    if(plus_minus=="+"){
      next_idx=now_idx+1;
    }else if(plus_minus=="-"){
      next_idx=now_idx-1;
    }
    if(next_idx<0){
      next_idx=0;
    }else if(next_idx>=child_info_arr.length){
      next_idx=child_info_arr.length-1;
    }

    if(now_idx==next_idx){
      return false;
    }
    if(now_idx==-1||next_idx==-1){
      return false;
    }

    let now_order_num=child_info_arr[now_idx]["a_order_num"];
    let next_order_num=child_info_arr[next_idx]["a_order_num"];
    child_info_arr[next_idx]["a_order_num"]=now_order_num;
    child_info_arr[now_idx]["a_order_num"]=next_order_num;

    let change_ordered_data_arr=[];
    for(let i=0;i<child_info_arr.length;i++){
      let child_info=child_info_arr[i];
      change_ordered_data_arr.push({
        a_seq:child_info["a_seq"],
        a_order_num:child_info["a_order_num"]
      });
    }

    let form_json_data={
      data_arr:change_ordered_data_arr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data_folder/write', form_json_data, get_axios_data())
    .then((response) => {
      if (response.data["result"] === "true") {
        myProps.list({});
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const get_tree_row_tags=()=>{
    let par_child_arr = tree_data["par_child_arr"];

    let tree_list_html: any = "";

    tree_list_html = par_child_arr.map((item: any, idx: number) => {
      return (
        <div key={idx}>
          <TreeRowBox info={item} key={idx} 
            ListTreeFunc={ListTreeFunc}
            change_order_num_by_ajax={change_order_num_by_ajax}
            delete_by_ajax={myProps.delete_by_ajax}
            open_write_popup={myProps.open_write_popup}
            is_show_manage_btn={myProps.is_show_manage_btn}
            is_show_all_tree={myProps.is_show_all_tree}
            show_tree_seq_arr={myProps.show_tree_seq_arr}
            set_show_tree_seq_arr={myProps.set_show_tree_seq_arr}
            select_folder_seq={myProps.select_folder_seq}
            set_select_folder_seq={myProps.set_select_folder_seq}
             />
        </div>
      );
    });

    return tree_list_html;
  };
  
  return (
  <div>
    {get_tree_row_tags()}
  </div>
  );
};
export default ListTreeArea;