//import DataFolderManageArea from "@/pages/comp/board/data_folder/common/manage";
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import { useDispatch, useSelector } from 'react-redux';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WriteFolderPopup from "@/pages/comp/board/data_folder/popup/write";
import SearchArea from "./area/search";
import ListTreeArea from "./area/list_tree";

function DataFolderManageArea(props: any) {
  let myProps = {
    listOpt: {},
    refresh_data: (inData: any) => { },
    ...props
  };

  const user = useSelector((state: any) => state.user);
  const [listOpt, setListOpt] = useState({
    "s_group_seq": "",
    "s_public_sort": "comp",
    "order_id": "a_order_num",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    ...myProps.listOpt
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [is_show_manage_btn,set_is_show_manage_btn]=useState(true);
  const [is_show_all_tree,set_is_show_all_tree]=useState(true);
  const [show_tree_seq_arr,set_show_tree_seq_arr]=useState([]);
  const [select_folder_seq,set_select_folder_seq]= useState("");

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    par_folder_info: null,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    list({});
  }, [user.user_seq]);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(form_json_data);

    if (strFunc.is_empty(user.comp_group_info["a_seq"])) {
      setInfoArr([]);
      return false;
    }
    form_json_data["s_group_seq"] = user.comp_group_info["a_seq"];

    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data_folder/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const delete_by_ajax=(inData:any)=>{
    let opt_obj={
      data_arr:[],
      ...inData
    };
    
    let del_data_arr=[];
    for(let i=0;i<opt_obj.data_arr.length;i++){
      if(!strFunc.is_empty(opt_obj.data_arr[i]["a_seq"])){
        del_data_arr.push(opt_obj.data_arr[i]);
      }
    }
    if(del_data_arr.length==0){
      alert("삭제할 데이터가 없습니다.");
      return false;
    }

    if(confirm("삭제 하시겠습니까?")==false){
      return false;
    }

    let form_json_data={
      data_arr:del_data_arr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/group/data/board_data_folder/delete', form_json_data, get_axios_data())
    .then((response) => {
      if (response.data["result"] === "true") {
        list({});
        myProps.refresh_data({});
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const open_write_popup=(inData:any)=>{
    let opt_obj={
      rowData:{},
      isUpdate:false,
      par_folder_info:null,
      ...inData
    };

    let pop_title = "등록";
    if(opt_obj.isUpdate){
      pop_title="수정";
      if(strFunc.is_empty(opt_obj.rowData["a_seq"])){
        alert("수정할 데이터가 없습니다.");
        return false;
      }
    }
    opt_obj.rowData["a_public_sort"]=listOpt["s_public_sort"];
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": opt_obj.isUpdate,
      "rowData": opt_obj.rowData,
      par_folder_info:opt_obj.par_folder_info,
      "title": pop_title,
      "sort": "write",
    });
  };

  return (
    <div>
      <SearchArea
        infoArr={infoArr}
        open_write_popup={open_write_popup}
        is_show_manage_btn={is_show_manage_btn}
        set_is_show_manage_btn={set_is_show_manage_btn}
        is_show_all_tree={is_show_all_tree}
        set_is_show_all_tree={set_is_show_all_tree}
        ></SearchArea>
      <ListTreeArea infoArr={infoArr}
        list={list}
        delete_by_ajax={delete_by_ajax}
        open_write_popup={open_write_popup}
        is_show_manage_btn={is_show_manage_btn}
        is_show_all_tree={is_show_all_tree}
        show_tree_seq_arr={show_tree_seq_arr}
        set_show_tree_seq_arr={set_show_tree_seq_arr}
        select_folder_seq={select_folder_seq}
        set_select_folder_seq={set_select_folder_seq}
        ></ListTreeArea>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "write" &&
            <WriteFolderPopup isUpdate={popupData.isUpdate} 
              rowData={popupData.rowData} 
              par_folder_info={popupData.par_folder_info}
              callback_data={() => { list({}); myProps.refresh_data({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></WriteFolderPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default DataFolderManageArea;