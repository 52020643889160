import Style from "./css/style.module.css";
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import FolderListArea from "./folder_list";
import FileListArea from "./file_list";
import strFunc from "@/lib/lyg/string";

function FileBoxListArea(props: any) {
  let myProps = {
    infoArr: [],
    folder_list: [],
    select_folder_seq: [],
    set_select_folder_seq: (inData: any) => { },
    select_par_folder_arr: [],
    set_select_par_folder_arr: (inData: any) => { },
    check_folder_seq_arr: [],
    set_check_folder_seq_arr: (inData: any) => { },
    check_file_seq_arr: [],
    set_check_file_seq_arr: (inData: any) => { },
    open_view_file_slide_popup: (inData: any) => { },
    go_in_folder: (inData: any) => { },
    go_out_folder: (inData: any) => { },
    go_back_folder: (inData: any) => { },
    ...props
  };
  let infoArr = myProps.infoArr;

  let select_folder_name_arr: any = [];
  for (let i = 0; i < myProps.select_par_folder_arr.length; i++) {
    select_folder_name_arr.push(myProps.select_par_folder_arr[i]["a_name"]);
  }

  const check_all_file = (e: any) => {
    let check_flag = e.target.checked;
    let check_file_seq_arr = [];
    if (check_flag) {
      for (let i = 0; i < infoArr.length; i++) {
        let info = infoArr[i];
        let file_pri_val = info["a_ymd"] + "," + info["a_seq"];
        check_file_seq_arr.push(file_pri_val);
      }
    }
    myProps.set_check_file_seq_arr(check_file_seq_arr);
  };

  const go_back_folder = () => {
    myProps.go_back_folder();
  };

  const get_select_folder_tags = () => {
    let select_folder_tags = myProps.select_par_folder_arr.map((item: any, idx: number) => {
      return (
        <span key={idx} className={Style.select_folder_span}
          onClick={() => {
            myProps.go_out_folder({
              folder_seq: item["a_seq"]
            });
          }}>
          {item["a_name"]}
        </span>
      );
    });
    return select_folder_tags;
  };

  return (
    <div>
      <div>
        <span className={Style.select_folder_span}
          onClick={() => {
            go_back_folder();
          }}>
          {strFunc.is_empty(myProps.select_folder_seq) ?
            "/"
            :
            <img src={left_arrow_ico} style={{ height: 20, background: "#fff", verticalAlign: "sub" }} />
          }
        </span>
        {get_select_folder_tags()}
      </div>
      <div className="mt-1">
        <div style={{ position: "relative" }}>
          <label>
            <input type="checkbox" className="mr-1" onChange={check_all_file} />
            <span className="ml-1">
              파일 전체 선택
            </span>
          </label>
          <span style={{ position: "absolute", right: 0 }}>
            <span className="ml-1">
              폴더 {myProps.folder_list.length} 개,
            </span>
            <span className="ml-1">
              파일 {infoArr.length} 개
            </span>
          </span>
        </div>
      </div>
      <div className="mt-1">
        <FolderListArea
          Style={Style}
          folder_list={myProps.folder_list}
          select_folder_seq={myProps.select_folder_seq}
          set_select_folder_seq={myProps.set_select_folder_seq}
          select_par_folder_arr={myProps.select_par_folder_arr}
          set_select_par_folder_arr={myProps.set_select_par_folder_arr}
          check_folder_seq_arr={myProps.check_folder_seq_arr}
          set_check_folder_seq_arr={myProps.set_check_folder_seq_arr}
          go_in_folder={myProps.go_in_folder}
        ></FolderListArea>
      </div>
      <div className="mt-1">
        <FileListArea
          Style={Style}
          infoArr={infoArr}
          check_file_seq_arr={myProps.check_file_seq_arr}
          set_check_file_seq_arr={myProps.set_check_file_seq_arr}
          open_view_file_slide_popup={myProps.open_view_file_slide_popup}
        ></FileListArea>
      </div>
    </div>
  );
};
export default FileBoxListArea;