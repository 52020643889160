import { useRef, forwardRef, useImperativeHandle } from 'react';

const DownLoadFileAndFolder = forwardRef((props: any, ref) => {
  let myProps = {
    ...props
  };

  const downloadFormRef = useRef<any>(null);
  const form_input_area_ref = useRef<any>(null);

  const go_download = (inData: any) => {
    let opt_obj = {
      server_url:process.env.REACT_APP_FILE_SERVER_URL,
      file_seq_arr:[],
      folder_seq_arr:[],
      ...inData
    };
    let server_url=opt_obj.server_url;
    let file_seq_arr=opt_obj.file_seq_arr;
    let folder_seq_arr=opt_obj.folder_seq_arr;

    if (downloadFormRef.current == null) {
      return false;
    }

    downloadFormRef.current.action = server_url + "/api//group_file/download_multy";
    form_input_area_ref.current.innerHTML = "";
    
    for(let i=0;i<file_seq_arr.length;i++){
      let file_seq=file_seq_arr[i];
      let tmp_input=document.createElement("input");
      tmp_input.type="hidden";
      tmp_input.name="file_seq_arr[]";
      tmp_input.value=file_seq;
      form_input_area_ref.current.appendChild(tmp_input);
    }
    for(let i=0;i<folder_seq_arr.length;i++){
      let folder_seq=folder_seq_arr[i];
      let tmp_input=document.createElement("input");
      tmp_input.type="hidden";
      tmp_input.name="folder_seq_arr[]";
      tmp_input.value=folder_seq;
      form_input_area_ref.current.appendChild(tmp_input);
    }

    downloadFormRef.current.submit();
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    go_download
  }));

  return (
    <>
      <form ref={downloadFormRef} method="post" >
        <input type="hidden" name="authorization" value={localStorage.intoyou_token} />
        <div ref={form_input_area_ref}>

        </div>
      </form>
    </>
  );
});
export default DownLoadFileAndFolder;