import { useRef } from 'react';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  let subject_select_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  const search_time_out_ref = useRef<any>(null);
  const go_search_list = (inData: any) => {
    if (search_time_out_ref.current) {
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current = setTimeout(() => {
      myProps.list(inData);
    }, 200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    myProps.setListOpt({ ...listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  return (
    <div className="text-right">
      <input type="text" className="search-input" name="s_title_like"
        style={{ width: "100px" }}
        value={listOpt.s_title_like} onChange={handleInputChange} placeholder="제목" autoComplete="off" />
      <span className="ml-1">
        <input type="text" className="search-input" name="s_writer_like"
          style={{ width: "100px" }}
          value={listOpt.s_writer_like} onChange={handleInputChange} placeholder="작성자" autoComplete="off" />
      </span>
      <select className="search-input ml-1" name="s_subject_name" value={listOpt.s_subject_name} onChange={handleInputChange}>
        <option value="">과목</option>
        {subject_select_option_arr.map((item: any, index: number) => {
          return (<option key={index} value={item.value}>{item.text}</option>);
        })}
      </select>
      <button className="btn btn-dark ml-1" onClick={() => { myProps.list({ now_page: "1" }); }}>검색</button>
    </div>
  );
};
export default SearchArea;