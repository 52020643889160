import strFunc from "@/lib/lyg/string";

class ListTreeFunc {
  static default_tree_json = {
    arr: [],
    depth: 0,
  };
  static get_tree_data = (inData: any) => {
    let opt_obj = {
      infoArr: [],
      ...inData
    };
    let this_obj = this;
    let infoArr = opt_obj.infoArr;

    let tree_data: any = {
      tree_json_arr: {},//{0:[],1:[]}
      max_depth: 0,
      par_child_arr: [],
    };

    //뎁스별로 데이터 정리
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let depth = parseInt(strFunc.uncomma(info["a_depth"]));
      if (tree_data["max_depth"] < depth) {
        tree_data["max_depth"] = depth;
      }
      if (tree_data["tree_json_arr"][depth] == undefined) {
        tree_data["tree_json_arr"][depth] = {
          ...this_obj.default_tree_json,
          depth: depth,
        };
      }
      tree_data["tree_json_arr"][depth].arr.push(info);
    }

    //부모 자식 관계 정리
    let par_child_arr = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let depth = parseInt(strFunc.uncomma(info["a_depth"]));
      if (depth == 0) {
        par_child_arr.push(info);
      }
    }
    
    //자식 붙이기
    for (let par_i = 0; par_i < par_child_arr.length; par_i++) {
      let par_info = par_child_arr[par_i];

      par_info = this_obj.add_child_at_par_info({
        par_info: par_info,
        info_arr: infoArr,
      });
    }
    tree_data["par_child_arr"] = par_child_arr;

    return tree_data;
  };
  static add_child_at_par_info = (inData: any) => {
    let opt_obj = {
      par_info: null,
      info_arr: [],
      ...inData
    };
    let this_obj = this;
    let par_info = opt_obj.par_info;
    let info_arr = opt_obj.info_arr;

    let par_depth = parseInt(strFunc.uncomma(par_info["a_depth"]));
    let child_depth = par_depth + 1;
    let child_arr = [];
    for (let i = 0; i < info_arr.length; i++) {
      let info = info_arr[i];
      let depth = parseInt(strFunc.uncomma(info["a_depth"]));
      if (depth == child_depth && par_info["a_seq"] == info["a_par_folder_seq"]) {
        //또있는지 무한반복
        info = this_obj.add_child_at_par_info({
          par_info: info,
          info_arr: info_arr,
        });
        child_arr.push(info);
      }
    }
    par_info["child_arr"] = child_arr;

    return par_info;
  };
  static get_child_from_info=(inData:any)=>{
    let opt_obj={
      info:null,
      ...inData
    };
    let info=opt_obj.info;
    let child_info_arr:any=[];
    let this_obj=this;

    let child_arr=info["child_arr"];
    if(child_arr&&child_arr.length>0){
      for(let i=0;i<child_arr.length;i++){
        let child_info=child_arr[i];
        child_info_arr.push(child_info);

        if(child_info["child_arr"]&&child_info["child_arr"].length>0){
          let child_child_info_arr=this_obj.get_child_from_info({
            info:child_info
          });
          child_info_arr=child_info_arr.concat(child_child_info_arr);
        }
      }
    }

    return child_info_arr;
  };
}
export default ListTreeFunc;