export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
  ico?:string;
  ico_active?:string;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"h_main",name:"메인",url:"/comp",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"main",name:"메인",url:"/comp",msg:"",hmenu:"h_main",isShow:false},
  {key:"login",name:"로그인",url:"/login",msg:"",hmenu:"",isShow:false},
  {key:"data_file_list",name:"자료실",url:"/comp/data_file/list",msg:"",hmenu:"h_main",isShow:true},
  {key:"data_list",name:"자료실",url:"/comp/board/data/list",msg:"",hmenu:"h_main",isShow:false},
  {key:"notice_list",name:"공지",url:"/comp/board/notice/list",msg:"",hmenu:"h_main",isShow:true},
  {key:"board_manage",name:"게시판관리",url:"/comp/board/manage/list",msg:"",hmenu:"h_main",isShow:false},
  {key:"data_folder_manage",name:"폴더관리",url:"/comp/board/data/folder/manage",msg:"",hmenu:"h_main",isShow:false},
];
export const hmenu_master_arr:string[]=[
  
];
export const smenu_master_arr:string[]=[
  "board_manage","data_folder_manage"
];