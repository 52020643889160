import CompLayout from "@/pcomponents/comp/layout/layout";
import DataFolderManageArea from "@/pages/comp/board/data_folder/common/manage";

function DataFolderManagePage(){
  
  return (
  <CompLayout>
    <DataFolderManageArea></DataFolderManageArea>
  </CompLayout>
  );
};
export default DataFolderManagePage;